import React from "react";
import Layout from "../components/Layout"


function Cart(){

    return ( 
       <Layout>
           <div>
               <div><h3>Cart Page</h3></div>
           </div>
       </Layout>
    )
}

export default Cart; 